import { AirMainRoute, AirLocation, DateTimeLocal, AirEvents, AirEvent,AirTimeLineEvent, AirRoutes, AirRoute, SegmentTime } from "../types/air_types"
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

 dayjs.extend(utc);
 dayjs.extend(timezone);

const getAirMainRoute = ( 
        from: AirLocation | null , 
        to: AirLocation | null , 
        departure_datetime_local: DateTimeLocal | null,
        arrival_datetime_local: DateTimeLocal | null ) : AirMainRoute[] => {

    let air_main_route: AirMainRoute[] = [];

    if(from){
        let from_with_time: AirMainRoute = {
            ...from,
            ...departure_datetime_local,
            actual: departure_datetime_local?.actual ?? null,
            estimated: departure_datetime_local?.estimated ?? null,
        };

        air_main_route = air_main_route ? [...air_main_route, from_with_time] : [from_with_time];
        }
        
    if(to){
            let to_with_time: AirMainRoute = {
                ...to, 
                ...arrival_datetime_local,
                actual: arrival_datetime_local?.actual ?? null,
                estimated: arrival_datetime_local?.estimated ?? null,
            }
         
            air_main_route = air_main_route ? [...air_main_route, to_with_time] : [to_with_time];
    }
  
    return air_main_route; 
}


const getActiveAirRouteStep = (airMainRoute:AirMainRoute[]): number => {

    if(airMainRoute.length === 2){
        
         if(airMainRoute[0].actual  && airMainRoute[1].actual){
             return 1;
         }

         if(airMainRoute[0].actual && !airMainRoute[1].actual){
             return 0;
         }

        return -1;
    }     
    
    return -1;
}

type GroupedEvent = {
    location: AirEvent['location'];
    events: AirTimeLineEvent[];
};
 
const getSortedAirEvents = (air_events: AirEvents) =>{

    let sorted_events_by_time  = sortAirEventsByDate(air_events);

    let grouped: Record<string, GroupedEvent> = {};

    sorted_events_by_time.forEach(event => {
        
            const iataCode = event.location.iata_code || 'unknown';

            if (!grouped[iataCode]) {
                grouped[iataCode] = {
                    location: event.location,
                    events: [] 
                };
            }

            grouped[iataCode].events.push(event);
        });
    
        return Object.values(grouped);
} 


const sortAirEventsByDate = (airEvents:AirEvents ) =>{
    
     let air_events:AirTimeLineEvent[] = airEvents
     .filter(event => event.datetime_local.actual || event.datetime_local.estimated)
     .map(event => ({
         ...event,
         active: event.datetime_local.actual ? 1: 0,
         sortDate: event.datetime_local.actual || event.datetime_local.estimated
     }))
     .sort((a, b) => new Date(a.sortDate??"").getTime() - new Date(b.sortDate??"").getTime())
     
     return air_events;
}


const findLastActualAirEvent = (events: AirTimeLineEvent[]): AirTimeLineEvent  | undefined => {

    const reversedEvents = events.slice().reverse();
    const lastActualEvent = reversedEvents.find(item => item.active);

return lastActualEvent;
}

type Point = AirLocation & { actual: string | number };

const getAirLocations = (routes: AirRoutes) =>{


       return  routes.flatMap((item) => {
           
        const points: Point[] = [];

        if (item.origin && item.departure_datetime_local) {
            points.push({
                ...item.origin,
                actual: item.departure_datetime_local.actual || 0,
              });
        }
        
        if (item.destination && item.arrival_datetime_local) {
            points.push({
                ...item.destination,
                actual: item.arrival_datetime_local.actual || 0,
              }); 
        }
        

          return points;
        })
        .filter((point,index,self)=>{
             return index === self.findIndex(p =>
                 p.name === point.name &&
                 p.state === point.state && 
                 p.country === point.country
             )
        })
        .sort((a,b) => new Date(a.actual).getTime() - new Date(b.actual).getTime());
}




  /**
   * Get the correct datetime value based on the `actual` boolean.
   * @param datetime - The datetime field containing estimated and actual values.
   * @param useActual - Whether to use the actual value or fallback to estimated.
   * @returns The appropriate datetime string.
   */
  const getDatetime = (datetime: DateTimeLocal, useActual: boolean): string |null => {

          if (!datetime) {
            return null; 
        }

        const actual = datetime.actual || null; 
        const estimated = datetime.estimated || null; 

        if (useActual && actual) {
            return actual;
        }

        if (estimated) {
            return estimated;
        }

        return null; 
  };



   const getTimeWithTimeZone = ( datetime_local: DateTimeLocal , route: AirRoute , type: string ) =>{

            const useActual =  datetime_local.actual ? true : false ; 

            let dateTime = getDatetime(datetime_local, useActual);

            let timezone = type == 'destination' ? route.destination?.timezone : route.origin?.timezone;

      return dateTime ? dayjs.tz(dateTime,(timezone || "UTC")) : null;
   }
 

  /**
   * Calculate travel times for a flight route, accounting for waiting times and timezones.
   * @param flightData - Flight data including route points and timezones.
   * @returns Object containing segment travel times, waiting times, and total travel time.
   */
  const calculateTravelTimes = (flightData: AirRoute[] | null ) => {
    const routes = flightData;
    if (!routes || routes.length === 0) {
      console.warn("No routes provided in flight data.");
        return {
          segmentTimes: [],
          totalTravelTime: 0,
          totalWaitingTime: 0,
          totalRouteTime: 0,
        };
    }

    
    let totalTravelTime = 0; // Total travel time in minutes
    let totalWaitingTime = 0; // Total waiting time in minutes
    const segmentTimes: SegmentTime[] = [];
   
    //Delayings
    let delayed_arrival_time = 0;
    let delayed_departure_time = 0;
    let delayed_arrival_for_last_point = 0


    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];

      // Determine whether to use actual or estimated times

      ///Departure
      const departureTime = getTimeWithTimeZone(route.departure_datetime_local, route , 'origin');
       //Arrival
      const arrivalTime =  getTimeWithTimeZone( route.arrival_datetime_local, route, 'destination');

    
      let travelTime: number = 0;
      
      if(arrivalTime !== null && departureTime !== null){
          travelTime = arrivalTime.utc().diff(departureTime.utc(), "minute");
      }
    
      if(travelTime){
        totalTravelTime += travelTime;
      }
      

      // Calculate waiting time (time between last segment's arrival and this segment's departure)
      let waitingTime = 0;
      if (i > 0) {
        const prevRoute = routes[i - 1];

        const prevArrivalTime = getTimeWithTimeZone(prevRoute.arrival_datetime_local, prevRoute,'destination'); 

         if(departureTime){
              waitingTime = departureTime.utc().diff(prevArrivalTime?.utc(), "minute");
         }      
        
        if (waitingTime) {
          totalWaitingTime += waitingTime;
        }


        //Delayed Time Arrival       
          if(prevRoute.arrival_datetime_local.actual && prevRoute.arrival_datetime_local.estimated){
  
              let arrivalTimeEstimated = dayjs.tz(
                  getDatetime(prevRoute.arrival_datetime_local, false),
                  prevRoute.destination?.timezone || 'UTC'
                );
  
              let arrivalTimeActual = dayjs.tz(
                  getDatetime(prevRoute.arrival_datetime_local, true),
                  prevRoute.destination?.timezone || 'UTC'
                );
                delayed_arrival_time =  arrivalTimeActual.utc().diff(arrivalTimeEstimated.utc(),'minute'); 
          } 
      }
       

      //Delayed Time  departure    
        if(route.departure_datetime_local.actual && route.departure_datetime_local.estimated){

            let departureTimeEstimated = dayjs.tz(
                getDatetime(route.departure_datetime_local, false),
                route.destination?.timezone || 'UTC'
              );

            let departureTimeActual = dayjs.tz(
                getDatetime(route.departure_datetime_local, true),
                route.destination?.timezone || 'UTC'
              );
            
              delayed_departure_time =  departureTimeActual.utc().diff(departureTimeEstimated.utc(),'minute'); 
        } 


        //Check dellay for last segment 
        if (i === routes.length - 1) {
            
            let arrivalTimeEstimated = dayjs.tz(
              getDatetime(route.arrival_datetime_local, false),
              route.destination?.timezone || 'UTC'
            );

            let arrivalTimeActual = dayjs.tz(
                getDatetime(route.arrival_datetime_local, true),
                route.destination?.timezone || 'UTC'
              );
          
             delayed_arrival_for_last_point = arrivalTimeActual.diff(arrivalTimeEstimated,'minute');   
        }
      

      segmentTimes.push({
        order_id: route.order_id,
        travelTime,
        waitingTime,
        delayed_departure_time,
        delayed_arrival_time,
        delayed_arrival_for_last_point,
      });
    }
    const totalRouteTime = totalTravelTime + totalWaitingTime;
    return {
      segmentTimes,
      totalTravelTime,
      totalWaitingTime,
      totalRouteTime,
    };
  };





export {
     getAirMainRoute,
     getActiveAirRouteStep,
     getSortedAirEvents,
     findLastActualAirEvent,
     getAirLocations,
     calculateTravelTimes,
}