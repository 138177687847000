
import { Tooltip } from 'react-leaflet';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { AirRoute, TravelTimes } from '../../types/air_types';
import { SvgIcon , SvgIconProps } from '@mui/material';
import { ReactComponent as MyIcon } from '../../svg/delay-icon.svg';
import ConvertToTime from '../ConvertToTime';
import { useTranslation } from 'react-i18next';

const DelaySvgIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
       <MyIcon />
    </SvgIcon>
  );

const renderConvertToTime = (timeInMinutes: number | null | undefined) => {
    return (timeInMinutes && timeInMinutes > 0) ? <ConvertToTime timeInMinutes={timeInMinutes} /> : null;
  };


const MapPointTooltip: React.FC<{travel_times: TravelTimes, route: AirRoute , isLast: boolean }> =({travel_times,route , isLast}) =>{

   const {t} = useTranslation();

  // Find the matched segment
  const matchedSegment = travel_times.segmentTimes.find(
    (item) => item.order_id === route.order_id
  );

  // If a matched segment is found, calculate the waiting time
    const waiting_time = renderConvertToTime(matchedSegment?.waitingTime);
    const delay_departure_time = renderConvertToTime(matchedSegment?.delayed_departure_time);
    const delay_arrival_time = renderConvertToTime(matchedSegment?.delayed_arrival_time);
    const delayed_arrival_for_last_point = renderConvertToTime(matchedSegment?.delayed_arrival_for_last_point);

    if ((!waiting_time && !delay_departure_time && !delay_arrival_time) || (!delayed_arrival_for_last_point && isLast === true )) {
        return null; // If none of the times are available, return null and do not render the tooltip
      }

    
  // Return the Tooltip only if waiting_time exists
  return (
    <Tooltip direction="center" offset={[0, -30]} className="point-tooltip">
      <div style={{ display: 'flex', flexDirection: 'column' }}>

      {isLast === false ? (
            <>
                {waiting_time && (
                <div style={{  display: 'flex', alignItems: 'center'  }}>
                    <HourglassEmptyIcon sx={{ fontSize: 13,  marginRight: 0.5 }} /> {t('waiting_time')} : {waiting_time}
                </div>
                )}
                {delay_departure_time && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DelaySvgIcon sx={{ fontSize: 13, marginRight: 0.5 }} /> {t("departure_delay")} : {delay_departure_time}
                </div>
                )}
                {delay_arrival_time && (
                <div style={{  display: 'flex', alignItems: 'center'  }}>
                    <DelaySvgIcon sx={{ fontSize: 13, marginRight: 0.5 }} /> {t('arrival_delay')} : {delay_arrival_time}
                </div>
                )}
            </>
            ) : (
            <>
                {delayed_arrival_for_last_point && (
                <div style={{  display: 'flex' ,alignItems: 'center'}}>
                    <DelaySvgIcon sx={{ fontSize: 13, marginRight: 0.5  }} /> {t('arrival_delay')} : {delayed_arrival_for_last_point}
                </div>
                )}
            </>
            )}
      </div>
    </Tooltip>
  );
}

export default MapPointTooltip;