
import L from 'leaflet';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';


const CustomMapMarker = (color: string) => {

    return L.divIcon({
        html: `<span style="background-color: ${color}; width: 10px; height: 10px; display: block; border-radius: 50%;"></span>`,
        className: 'custom-icon',
        iconSize: [20, 20], 
        iconAnchor: [5, 5],
    });
  };

const CustomMapIcon = (color: string) =>{

  const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" style="fill: ${color}"/></svg>`;
  const svgIconBase64 = btoa(svgIcon);
  const svgIconUrl = `data:image/svg+xml;base64,${svgIconBase64}`;
      
     return L.icon({
        iconUrl: svgIconUrl,
        iconSize: [24, 24], 
        iconAnchor: [12, 12], 
        className: 'custom-icon',
     })
}

  const MapSelectorBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1000,
    top: 85,   //bottom: 15
    left: '1.2%', // left 95%
    width: 30,
    height: 30,
    backgroundColor: 'white',
    borderRadius: '4px',
    backgroundSize: 'cover',
    cursor: 'pointer',
  }));


  const toolTip = () =>(
    {
      background: 'none !important',
      border: 'none !important',
      boxShadow: 'none !important',
      color: 'black',
      fontSize: '14px',
      padding: '0 !important'
    }
  )


export {
    CustomMapMarker,
    CustomMapIcon,
    MapSelectorBox,
    toolTip
}
  