import { useTranslation } from "react-i18next";

const ConvertToTime: React.FC <{timeInMinutes: number}> =({timeInMinutes}) => {

    const {t} = useTranslation();
       
    if (timeInMinutes <= 0) return null;
   
      const days =  Math.floor(timeInMinutes / (24 * 60));
      const hours = Math.floor((timeInMinutes % (24 * 60)) / 60);
      const minutes = timeInMinutes % 60;

      let result = '';

      if (days > 0) {
        let d = days > 1 ? t('days') : t('day');   

        result += `${days}  ${d} `;
      }
       
      if (hours > 0) {

        result += `${hours}  ${t('h')} `;
      }
      if (minutes > 0) {
        result += `${minutes} min`;
      }

      return <span>{result.trim()}</span>;
  
  };

  export default ConvertToTime;