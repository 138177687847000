import { Tooltip } from 'react-leaflet';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AirRoute, TravelTimes } from '../../types/air_types';
import ConvertToTime from '../ConvertToTime';



const PolyLineTooltip: React.FC<{travel_times: TravelTimes, route: AirRoute  }> = ({travel_times , route})=> {
   

      let travel_time;

        const matchedSegment = travel_times.segmentTimes.find(
          item => item.order_id === route.order_id
        );
        if (matchedSegment && matchedSegment.travelTime > 0 ) {     
             travel_time = <ConvertToTime timeInMinutes={matchedSegment.travelTime} />;
        } else {
            travel_time = null;
        }
    
                    
        return travel_time ? (
            <Tooltip permanent direction="center" offset={[0, -20]} className="custom-tooltip">
              <AccessTimeIcon sx={{ fontSize: 13 }} />: {travel_time}
            </Tooltip>
          ) : null;
     

}

export default PolyLineTooltip;